import React from "react"
import Consigli from "../../components/fondue/consigli"
import HeaderFondue from "../../components/fondue/headerFondue"
import baseStyles from "./base.module.css"
import Footer from "../../components/footer"
import imgFondue from "../../images/low_3Alpeggi1A-Fototipo500.jpg"

export default () => (
   <div className={baseStyles.container}>
      <HeaderFondue/>

      <div className={baseStyles.title}>MISCELA FONDUE "3 ALPEGGI TICINESI"</div>


      <div className={baseStyles.flContainer}>

        <div className={baseStyles.flColumnPhoto}>
         <img  className={baseStyles.image}
                alt="foto confezione"
                src={imgFondue}
              />
        </div>

        <div className={baseStyles.flColumn}>
          <p style={{fontStyle:"italic"}}>preparato di formaggio da consumarsi previa cottura</p>
          <p>Ingredienti: Formaggio d’Alpe Ticinese DOP grasso a pasta
        semidura con LATTE crudo vaccino (89%) e caprino (8%)
        appositamente selezionati da 3 Alpeggi delle 3 principali vallate
        ticinesi: Leventina, Blenio, Maggia. Addensante amido di mais.</p>
              <p>Prodotto in Svizzera.</p>
      <p>Confezionato in atmosfera protettiva. Conservare a +2°C - +5°C.</p>
        </div>

        <div className={baseStyles.flColumnTable}>
          <table className={baseStyles.table}>
            <thead>
            <tr>
              <th colSpan="2">Valori nutrizionali medi per 100g:</th>
            </tr>
            </thead>
            <tbody>
            <tr><td>Energia</td><td>1744 kJ / 420 kcal</td></tr>
            <tr><td>Grassi</td><td>34 g</td></tr>
            <tr><td>di cui saturi</td><td>21 g</td></tr>
            <tr><td>Carboidrati</td><td>{'<0,5 g'}</td></tr>
            <tr><td>di cui zuccheri</td><td>{'<0,5 g'}</td></tr>
            <tr><td>Proteine</td><td>25 g</td></tr>
            <tr><td>Sale</td><td>1,3 g</td></tr>
            </tbody>
          </table>
      </div>
      </div>

   
      <Consigli/>
      <Footer />
      </div>
)
